import React from "react";

const Lending = () => {
  return (
    <div className="App">
      <header>
        <div className="logo">
          <h1>cRik11</h1>
        </div>
        <nav>
          <ul>
            <li>
              <a href="#">Home</a>
            </li>
            <li>
              <a href="#why-us">Why Us</a>
            </li>
            <li>
              <a href="#our-mission">Our Mission</a>
            </li>
            <li>
              <a href="#contact">Contact</a>
            </li>
          </ul>
        </nav>
      </header>
      <section className="home-container">
        <div className="home-wrapper">
          <h2>Game-Changing Cricket Fantasy</h2>
          <p>
            cRik11 is powered by Web3 on <strong>Mina</strong>.Track player
            performance with precision, receiving real-time updates on scores
            and stats.Join us and elevate your cricket fantasy experience like
            never before.
          </p>
          <button>Get Started For Free</button>
        </div>
      </section>
      <section id="why-us" className="why-us">
        <div className="home-wrapper">
          <h1>
            Crik11 for unrivaled insights into cricket batting. With advanced
            algorithms and real-time tracking, analyze shots, scoring, and
            patterns for informed decisions. Elevate your game with us!
          </h1>
        </div>
      </section>
      <section id="our-mission" className="mission">
        <div className="home-wrapper">
          <h1>
            Crik11 sparks cricket fantasy like never before. With Web3, we craft
            a platform where every player shines. Through blockchain's
            transparency, we redefine the game, making every match magical. Join
            us on this immersive journey to reshape cricket fantasy.
          </h1>
        </div>
      </section>
      <section id="contact" className="contact">
        <div className="contact-wrapper">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/8/82/Telegram_logo.svg"
            alt="Telegram Logo"
            width={50}
            height={50}
          />
          <img
            src="https://abs.twimg.com/responsive-web/client-web/icon-ios.b1fc7275.png"
            alt="Twitter Logo"
            width={50}
            height={50}
          />
          <img
            src="https://logos-world.net/wp-content/uploads/2020/11/Discord-Logo.png"
            alt="Discord Logo"
            width={50}
            height={50}
          />
        </div>
      </section>
    </div>
  );
};

export default Lending;
